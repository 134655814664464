import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  computed,
  inject,
  signal,
  effect,
  untracked,
} from '@angular/core';
import { SubSink } from 'subsink';
import {
  FormGroup,
  FormBuilder,
  Validators,
  ReactiveFormsModule,
  FormsModule,
} from '@angular/forms';
import { NgbModal, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { SetOwnerDialogComponent } from '../set-owner-dialog/set-owner-dialog.component';
import {
  User,
  Profile,
  Maybe,
  Location,
  MeQuery,
  Organization,
  GetProfileDetailedGQL,
  Coordinates,
  CustomFeatures,
  MeProfileFragment,
} from '@designage/gql';
import {
  ToasterService,
  CurrentUserService,
  ProfileSettingsService,
  ProfileService,
} from '@desquare/services';
import {
  IProfileForm,
  ICountry,
  ILocationForm,
  ICoordinates,
} from '@desquare/interfaces';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { createMapEventSubscriber } from '@desquare/designage2-events';
import {
  getData as getCountryListData,
  getCode as getCountryListCode,
} from 'country-list';
import { lastValueFrom } from 'rxjs';
import { timeFormats, dateFormats, DateTimeFormat } from '@desquare/models';
import { DateTime } from 'ts-luxon';
import { timeZonesNames } from '@vvo/tzdb';
import { cloneDeep } from 'lodash';
import { OrganizationSelectionComponent } from '@designage/app/organization/organization-selection/organization-selection.component';
import { LocationTabSelectionComponent } from '@designage/app/location/location-tab-selection/location-tab-selection.component';
import { LocationFormComponent } from '@designage/app/location/location-form/location-form.component';
import { TypeaheadComponent } from '@desquare/components/common/src/typeahead/typeahead.component';
import { DateProxyPipe } from '@desquare/components/common/src/pipe/pipe/date-proxy.pipe';
import { StorageSummaryComponent } from '@desquare/components/common/src/storage-summary/storage-summary.component';
import { LocationsStore } from '@desquare/stores';
import { JsonPipe } from '@angular/common';
import { CreateLocationDialogComponent } from '@designage/app/location/create-location-dialog/create-location-dialog.component';

enum Tab {
  PROFILE_FEATURES,
  PROFILE_SETTINGS,
  STORAGE_SUMMARY,
}
@Component({
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    OrganizationSelectionComponent,
    TypeaheadComponent,
    DateProxyPipe,
    StorageSummaryComponent,
    NgbTooltip,
  ],
  selector: 'app-profile-form',
  templateUrl: './profile-form.component.html',
  styleUrls: ['./profile-form.component.scss'],
})
export class ProfileFormComponent implements OnInit, OnDestroy {
  locationsStore = inject(LocationsStore);
  profileService = inject(ProfileService);

  private mapEventSubscriber = createMapEventSubscriber(window);
  private subs = new SubSink();

  private _profile!: Partial<Profile>;
  @Input() set profile(profile: Partial<Profile>) {
    if (profile === this._profile) return;

    if (profile) {
      this._profile = profile;
      this.defaultOwner = profile.owner;
    }
  }
  get profile() {
    return this._profile;
  }

  @Input() formId = 'profileForm';
  @Input() activeOrganizations!: Organization[];
  @Input() parentFormGroup?: FormGroup;
  @Input() isCreate = true;
  @Output() profileFormSubmitted = new EventEmitter<IProfileForm>();
  @Output() valid = new EventEmitter<boolean>();
  @Output() pristine = new EventEmitter<boolean>();

  currentUser!: Maybe<MeQuery['me']>;
  loaderMessage!: string;
  isLocationFormValid = false;
  loading = false;
  tabs = Tab;
  currentTab: Tab = Tab.PROFILE_SETTINGS;
  dateNow = new Date();
  timezones: string[] = timeZonesNames;
  // profileTimezone = '';
  // timezoneOffset = '';
  dateFormats = dateFormats;
  timeFormats = timeFormats;
  profileDateFormat!: DateTimeFormat;
  profileTimeFormat!: DateTimeFormat;
  availableFeatures: CustomFeatures[] = [];
  selectedFeatures: CustomFeatures[] = [];

  private _selectedUsers: User[] = [];
  get selectedUsers(): User[] {
    return this._selectedUsers;
  }
  set selectedUsers(users: User[]) {
    this._selectedUsers = users;
  }

  defaultProfileText!: string;
  activeUsers: User[] = [];
  profileForm!: FormGroup;
  locationForm!: FormGroup;
  defaultOwner?: Maybe<User>;
  defaultLocation = signal<Location | null>(null);
  countries!: ICountry[];

  timezonePreview = computed(() =>
    DateTime.now()
      .setZone(this.profileSettingsService.profileTimezone())
      .toFormat('ZZ'),
  );

  timezoneOffset = computed(
    () => this.profileSettingsService.getProfileTimezoneValue().offset,
  );

  profileTimezone = computed(
    () => this.profileSettingsService.getProfileTimezoneValue().timezone,
  );

  get isSuperAdmin() {
    return this.currentUserService.isSuperAdmin;
  }

  constructor(
    private formBuilder: FormBuilder,
    private toasterService: ToasterService,
    private modalService: NgbModal,
    private translateService: TranslateService,
    private currentUserService: CurrentUserService,
    private getProfile: GetProfileDetailedGQL,
    private profileSvc: ProfileService,
    private profileSettingsService: ProfileSettingsService,
  ) {
    effect(() => {
      if (!this.isCreate) {
        const defaultLocation = this.locationsStore.defaultLocation();
        untracked(() => {
          this.defaultLocation.set(defaultLocation ?? null);
        });
      }
    });
  }

  get showOrganizationDropDown() {
    return this.currentUserService.isSuperAdmin;
  }
  get isEditing() {
    return this.profile && this.profile.id;
  }

  // get locationMapUrl(): string {
  //   return `${environment.urls.watchtowerApp}/embed-map?locationId=${this.profile?.defaultLocation?.id}`;
  // }

  ngOnInit() {
    this.initVariables();
    this.initSubscriptions();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  initVariables() {
    this.timezones = timeZonesNames;
    this.loaderMessage = 'FETCHING_MAP';
    this.valid.emit(false);
    this.pristine.emit(true);
    this.countries = getCountryListData();
    this.availableFeatures.push(CustomFeatures.UserMfa);
    this.availableFeatures.push(CustomFeatures.LayoutRegions);
    this.availableFeatures.push(CustomFeatures.VirtualOrientation);
    this.availableFeatures.push(CustomFeatures.EventChannels);
    this.profileSettingsService.setProfileTimezone(
      Intl.DateTimeFormat().resolvedOptions().timeZone,
    );
  }

  async initSubscriptions() {
    this.currentUser = this.currentUserService.currentUser;

    const id = this.currentUserService.getCurrentProfileId();
    // TODO: this same query is done in parent component Profile-settings, there should be only one query
    const { data } = await lastValueFrom(this.getProfile.fetch({ id }));
    const profile = cloneDeep(data.profile);

    if (profile && !this.isCreate) {
      this.profile = profile;
    } else {
      this.profile = {
        users: [this.currentUser],
        owner: this.currentUser,
      } as Profile;
    }

    this.getProfileSettings();

    this.selectedFeatures = [];
    for (const f of this.profile.features || []) {
      if (f?.id) {
        this.selectedFeatures.push(f.id);
      }
    }
    this.initForms();

    // patch form values
    this.profileForm.patchValue(
      {
        id: this.profile?.id,
        name: this.profile?.name,
        description: this.profile?.description,
        website: this.profile?.website,
        ownerId: this.profile?.owner?.id
          ? this.profile?.owner.id
          : this.currentUser
            ? this.currentUser.id
            : null,
        // locationInput: this.locationForm?.value, // TODO: need to relocate (?)
        defaultLocationId: this.profile?.defaultLocationId || null,
        organizationUid: this.profile?.organization?.active
          ? this.profile?.organization?.organizationUid
          : null,
        timezone: this.profileTimezone(),
        dateFormat: this.profileDateFormat,
        timeFormat: this.profileTimeFormat,
        features: this.selectedFeatures,
      },
      { emitEvent: false },
    );

    // set additional variables
    this.defaultOwner = this.profile.owner;

    if (this.profile.users) {
      this.selectedUsers = this.profile.users as User[];
      this.activeUsers = (
        this.profile.users
          ? this.profile.users.filter((x) => x && x.active)
          : []
      ) as User[];
    }

    if (!this.profile.owner?.active) {
      this.profileForm.patchValue({ ownerId: null });
    }

    // if user is a Reseller, set organization to customer plan 1
    if (this.currentUserService.isReseller) {
      // there should be a better way to get the customer device plan 1, like getting it through
      // fetch, or getting it from an enum
      const customerDevicePlan1Uid =
        'c20fa6442902050cf8c908abb22cb0aaa5777fc337edc6536c';
      this.profileForm.patchValue(
        { organizationUid: customerDevicePlan1Uid },
        { emitEvent: false },
      );
    }

    this.subs.sink = this.translateService
      .get('THIS_PROFILE')
      .subscribe((value) => {
        this.defaultProfileText = value;
      });
  }

  isFeatureSelected(feature: CustomFeatures) {
    return this.selectedFeatures.includes(feature);
  }
  toggleFeature(feature: CustomFeatures) {
    if (this.selectedFeatures.includes(feature)) {
      this.selectedFeatures = this.selectedFeatures.filter(
        (x) => x !== feature,
      );
    } else {
      this.selectedFeatures.push(feature);
    }
    this.profileForm.patchValue({
      features: this.selectedFeatures,
    });
    console.log('this.profileForm', this.profileForm.value);
    this.profileForm.controls.timeFormat.markAsDirty();
    this.emitPristine();
  }

  setLocationCoordinates(coordinates: ICoordinates) {
    this.locationForm.patchValue(
      { coordinates: { x: coordinates.lng, y: coordinates.lat } },
      { emitEvent: false },
    );
  }

  emitPristine() {
    this.pristine.emit(false);
  }

  initForms() {
    const FORM_GROUP_NAME = 'profile';

    this.profileForm =
      (this.parentFormGroup?.controls[FORM_GROUP_NAME] as FormGroup) ??
      this.formBuilder.group({
        id: [this.profile?.id || null],
        name: [this.profile?.name || null, Validators.required],
        description: [this.profile?.description || null],
        website: [this.profile?.website || null],
        ownerId: [
          this.profile?.owner?.id
            ? this.profile?.owner.id
            : this.currentUser
              ? this.currentUser.id
              : null,
          Validators.required,
        ],
        // locationInput: this.locationForm?.value, // TODO: need to relocate (?)
        organizationUid: [
          this.profile?.organization?.active
            ? this.profile?.organization?.organizationUid
            : null,
          Validators.required,
        ],
        defaultLocationId: [
          this.profile?.defaultLocationId || null,
          this.isCreate ? undefined : Validators.required,
        ],
        timezone: [
          this.profileTimezone() ||
            this.profileSettingsService.setProfileTimezone(
              Intl.DateTimeFormat().resolvedOptions().timeZone,
            ),
          this.isCreate ? undefined : Validators.required,
        ],
        dateFormat: [this.profileDateFormat],
        timeFormat: [this.profileTimeFormat],
        features: undefined,
      });

    console.log('this.profileForm', this.profileForm.value);

    if (this.parentFormGroup) {
      this.parentFormGroup.addControl(FORM_GROUP_NAME, this.profileForm);
    }

    this.subs.sink = this.profileForm.controls.ownerId.valueChanges.subscribe(
      (val) => {
        this.setOwner(val);
      },
    );

    this.subs.sink = this.profileForm.valueChanges.subscribe(() => {
      if (this.profileForm) {
        this.pristine.emit(this.profileForm.pristine);
        this.valid.emit(this.profileForm.valid);
      }
    });
  }

  async submitForm(values: IProfileForm) {
    if (this.profile && this.profile.id) values.id = this.profile.id;

    if (this.currentUser && this.currentUser.id) {
      values.userIds = [this.currentUser.id];
      values.ownerId = this.currentUser.id;
    }

    // this.profileFormSubmitted.emit(values);
  }

  setOwner(userId: string) {
    if (
      this.profile.users &&
      ((this.defaultOwner && userId !== this.defaultOwner.id) ||
        !this.defaultOwner)
    ) {
      const user = this.profile.users.find((x) => !!x && x.id === userId);
      if (user) {
        const modalReference = this.showTransferOwnershipDialog(
          user.displayName,
        );
        modalReference.result.then(
          () => {
            this.profile.owner = user;
            this.pristine.emit(false);
          },
          () => {
            if (this.defaultOwner?.active) {
              this.profileForm.patchValue({ ownerId: this.defaultOwner.id });
            } else {
              this.profileForm.patchValue({ ownerId: null });
            }
          },
        );
      }
    }
  }

  showTransferOwnershipDialog(userDisplayName?: string | null) {
    const modalReference = this.modalService.open(SetOwnerDialogComponent);
    modalReference.componentInstance.user = userDisplayName;
    if (this.profile) {
      modalReference.componentInstance.profile = this.profile.name;
    }

    return modalReference;
  }

  setOrganization(selectedOrganization: Organization) {
    if (
      this.profileForm.controls.organizationUid.value !==
      selectedOrganization.organizationUid
    ) {
      this.profileForm.patchValue({
        organizationUid: selectedOrganization.organizationUid,
      });
      this.profileForm.controls.organizationUid.markAsDirty();
    }
  }

  getProfileSettings() {
    // this.profileTimezone =
    //   this.profileSettingsService.getProfileTimezoneValue().timezone;

    this.profileDateFormat = this.profileSettingsService.profileDateFormat();
    // this.profileForm.patchValue({ dateFormats: this.profileDateFormat.name });

    this.profileTimeFormat = this.profileSettingsService.profileTimeFormat();
  }

  updateDefaultLocation(e: Event) {
    const locationId = (e.target as HTMLSelectElement).value;
    console.log('updateDefaultLocation', locationId);
    this.defaultLocation.set(
      this.locationsStore.getLocation(locationId) ?? null,
    );
  }

  editDefaultLocation() {
    const modalRef = this.modalService.open(CreateLocationDialogComponent, {
      size: 'lg',
      backdrop: 'static',
    });
    const componentInstance =
      modalRef.componentInstance as CreateLocationDialogComponent;
    componentInstance.create.set(false);
    componentInstance.location.set(this.defaultLocation() as IProfileForm);
    modalRef.result
      .then((result) => {
        console.log('editDefaultLocation', result);
      })
      .catch(() => {})
      .finally(() => {
        modalRef.dismiss();
      });
  }

  createDefaultLocation() {
    const modalRef = this.modalService.open(CreateLocationDialogComponent, {
      size: 'lg',
      backdrop: 'static',
    });
    const componentInstance =
      modalRef.componentInstance as CreateLocationDialogComponent;
    componentInstance.create.set(true);
    modalRef.result
      .then((result) => {
        console.log('createDefaultLocation', result);
      })
      .catch(() => {})
      .finally(() => {
        modalRef.dismiss();
      });
  }

  updateTimezone(timezone: string) {
    console.log('updateTimezone', timezone);

    this.profileForm.controls.timezone.setValue(timezone);
    this.profileForm.controls.timezone.markAsDirty();

    this.profileSettingsService.setProfileTimezone(timezone);
    this.getProfileSettings();
  }

  setLoaderMessage(message: Event) {}
}
